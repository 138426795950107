import { Component, OnInit } from '@angular/core';
import { MentorService } from '../../services/mentor.service';
import { toJSON, toDOM } from "dom-to-json";
import { ActivatedRoute,Router } from '@angular/router';
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { form } from "../../../assets/js/getFormData";
declare var $:any;

@Component({
  selector: 'ngx-mentorformpreview',
  templateUrl: './mentorformpreview.component.html',
  styleUrls: ['./mentorformpreview.component.scss']
})
export class MentorformpreviewComponent implements OnInit {
  label: string;
  parent: HTMLElement;
  checked: boolean;
  formid:string;
  jsonOutput: any;
  arrayDataPush: any = {};
  criteria_label = [];
  criteria_label3 = [];
  criteria_label4 = [];
  formids = [];
  step2criteria = [];
  step3criteria = [];
  step4criteria = [];
  step2filter = [];
  step3filter = [];
  step4filter = [];
  score_schema = [];
  validation = [];

  constructor(private mentorService:MentorService,private _route:ActivatedRoute,private toastrService: NbToastrService,private router:Router) { 
    this._route.params.subscribe((params)=>{
      this.formid = params['id'];
    })

    this.mentorService.getScoreSchema().subscribe((res)=>{
      this.score_schema = res.score_schema;
      console.log('score_schema',this.score_schema);
    })
  }

  ngOnInit(): void {
    this.converttohtml().then(() => {
      document.getElementById('step1').style.visibility = "visible";
      document.getElementById('step1').classList.remove('heightzero');
      document.querySelectorAll('.addhere').forEach((element,i)=>{
        console.log('element_add_here',element);
        element.setAttribute('id',`addhere_${i}`)
      })
      
      document.querySelectorAll('.add_question').forEach((element,i)=>{
        element.setAttribute('id',`addquestion_${i}`);
      })

      document.querySelectorAll('.removequestion').forEach((element,i)=>{
        element.setAttribute('id',`removequestion_${i}`);
      })

      document.querySelectorAll('.removeinui').forEach((element,i)=>{
        element.setAttribute('id',`removeinui_${i}`)
      })

      // document.querySelectorAll('.scorable').forEach((element,i)=>{
      //   console.log('scorable_element',element);
      //   if(document.getElementById(`scorable${i}`)!=null){
      //     document.getElementById(`scorable${i}`).onchange = (e:any) => {
      //       alert(e.target.value)
      //     }
      //   }
      //   if(document.getElementById(`scorable_${i}`)!=null){
      //     document.getElementById(`scorable_${i}`).onchange = (e:any) => {
      //       alert(e.target.value)
      //     }
      //   }
      // })

      document.querySelectorAll("[value = 'scorable']").forEach((e,i)=>{
        console.log('index_scorable',i);
        e.setAttribute('id',`scorable_${i}`)
        console.log('label_previous',e.previousElementSibling.textContent.replace(/\*$/, ''))
          document.getElementById(`scorable_${i}`).onchange = (e:any) => {
            if(e.target.checked == true){
              this.label = document.getElementById(`scorable_${i}`).previousElementSibling.textContent.replace(/\*$/, '');
              document.querySelectorAll('.answer').forEach((element,i)=>{
                element.setAttribute("id",`answer${i}`);
              })
              document.getElementById(`answer${i}`).classList.remove('d-none');
              this.test(e,i)
            }
            else{
              document.getElementById(`answer${i}`).classList.add('d-none');
              
            }
          }
      })
        if(sessionStorage.getItem('formpreview')=='true'){
          $('.mandatory').attr('class','d-none');
          $('.mandatorytext').attr('class','d-none');
          $('#addquestion').attr('class','d-none');
          $('#removequestion').attr('class','d-none');
          $('.addmoreradio').attr('class','d-none');
          $('.selectfield').attr('class','d-none');
          $('#next_step_4').text('Submit');
          $('.mandatoryplus').attr('class','d-none');
          $('.mandatoryminus').attr('class','d-none');
          $('.hide').attr('class','d-none');
          $('.hideonpreview').attr('style','display:none');

          document.querySelectorAll('.slider').forEach((element,i)=>{
            element.setAttribute('class','d-none');
          })

          document.querySelectorAll('.switch_disabled').forEach((element,i)=>{
            element.setAttribute('class','d-none');
          })
          document.getElementById('removequestion').setAttribute('class','d-none');

          document.querySelectorAll('.step3val').forEach((element,i)=>{
            element.classList.remove('d-none');
            element.className+=' d-block'
          })

          document.querySelectorAll('.removeinui').forEach((element,i)=>{
            element.classList.remove('border');
            element.classList.remove('border-5');
            element.classList.remove('border-secondary');
          })

        }
        
        document.querySelectorAll(".selectfield").forEach((element, i) => {
          console.log("selectindex", i);
          console.log("element", element);
         
    
          element.addEventListener("change", ($event: any) => {
            console.log("hello_answer");
            document.querySelectorAll('.answer').forEach((element,i)=>{
              element.setAttribute("id",`answer${i}`);
            })
              this.test($event, i);
          }); 
        });

        console.log("after conversion");
        console.log("add question", document.getElementById("addquestion"));
        document.querySelectorAll('.add_question').forEach((element,i)=>{
          console.log('element_add_question_',element);
          document.getElementById(`addquestion_${i}`).onclick = (e) => {
            console.log('clicked ok -----');
            e.preventDefault();
            this.addQuestions(i);
          }
        })

        document.querySelectorAll('.removequestion').forEach((element,i)=>{
          document.getElementById(`removequestion_${i}`).onclick = (e) => {
            e.preventDefault();
            document.getElementById(`removeinui_${i}`).remove();
          }
        })

        let nextbtn1 = document.getElementById("next_step_1") as HTMLElement;
        let nextbtn2 = document.getElementById("next_step_2") as HTMLElement;
        let nextbtn3 = document.getElementById("next_step_3") as HTMLElement;
        let nextbtn4 = document.getElementById("next_step_4") as HTMLElement;
  
        let prevbtn2 = document.getElementById("prev_step_2") as HTMLElement;
        let prevbtn3 = document.getElementById("prev_step_3") as HTMLElement;
        let prevbtn4 = document.getElementById("prev_step_4") as HTMLElement;
        
        document.getElementById("step2").setAttribute("class","d-none");
        document.getElementById("step3").setAttribute("class", "d-none");
        document.getElementById("step4").setAttribute("class", "d-none");

        nextbtn1.onclick = () => {
          document.getElementById("step1").style.visibility = "hidden";
          document.getElementById("step1").setAttribute("class", "heightzero");
          document.getElementById("step2").setAttribute("class", "d-block");
          document.getElementById("step2").style.visibility = "visible";

          var parentContainer = document.getElementById('step2');
          var elementsWithClass = parentContainer.getElementsByClassName('criteriaform');
          console.log('getbyclass_step2',elementsWithClass);
          var labelsWithClass = parentContainer.getElementsByClassName('score_label');

          // for (var i = 0; i < elementsWithClass.length; i++) {
          //   console.log('elementswithclass____123',elementsWithClass);
          //   this.formids.push(elementsWithClass[i].getAttribute('id'));
          // }

          this.criteria_label = [];

          var score = [];

          for (var i = 0; i < labelsWithClass.length; i++) {
            this.criteria_label.push(labelsWithClass[i].textContent.replace(/\*$/, ''));
            for(var j = 0 ; j < this.criteria_label.length ; j++){
              console.log('criteria_label_j',this.criteria_label[j]);
              // let data = this.score_schema.find((item)=>{
              //   return item[this.criteria_label[j]] == 'please select your years of education'
              // });
              let data = this.score_schema.find((item)=>{
                return Object.keys(item).includes(this.criteria_label[j]);
              })
              console.log('data_criteria',data);
              score.push(data);
            }
          }

          console.log('score_value-----',score);

          for (var i = 0; i < elementsWithClass.length; i++) {
            console.log('elementswithclass____123',elementsWithClass[i]);
            var data = this.score_schema.find((item)=>{
              return Object.keys(item).includes(elementsWithClass[i].getAttribute('data-name'));
            })
            //console.log('data_criteria__1234',Object.values(data[elementsWithClass[i].getAttribute('data-name')]));
            var data_criteria:string[] = Object.values(data[elementsWithClass[i].getAttribute('data-name')]);
            this.formids.push(elementsWithClass[i].getAttribute('id'));
            for(var j = 0 ; j < this.formids.length ; j++){
              document.getElementById(this.formids[i]).querySelectorAll('input').forEach((e,i)=>{
                e.value = data_criteria[i]
              })
            }
          }

          console.log('criteria_label_12356',this.criteria_label);
          console.log('formid_btn1',this.formids);
          this.personalJson();
        };
  
        nextbtn2.onclick = () => {
          document.getElementById("step2").style.visibility = "hidden";
          document.getElementById("step2").setAttribute("class", "heightzero");
          document.getElementById("step3").setAttribute("class", "d-block");
          document.getElementById("step3").style.visibility = "visible";

          var parentContainer = document.getElementById('step3');
          var elementsWithClass = parentContainer.getElementsByClassName('criteriaform');
          var labelsWithClass = parentContainer.getElementsByClassName('score_label');

          var formids = [];

          for (var i = 0; i < elementsWithClass.length; i++) {
            console.log('step3 elements',elementsWithClass[i]);
            //this.formids.push(elementsWithClass[i].getAttribute('id'));
            console.log('elementswithclass____123',elementsWithClass[i]);
            var data = this.score_schema.find((item)=>{
              return Object.keys(item).includes(elementsWithClass[i].getAttribute('data-name'));
            })
            console.log('data_criteria__1234',Object.values(data[elementsWithClass[i].getAttribute('data-name')]));
            var data_criteria:string[] = Object.values(data[elementsWithClass[i].getAttribute('data-name')]);
            formids.push(elementsWithClass[i].getAttribute('id'));
            console.log('formids_step3',this.formids);
            for(var j = 0 ; j < formids.length ; j++){
              document.querySelector(`#step3 #${formids[i]}`).querySelectorAll('input').forEach((e,i)=>{
                console.log('e_3',e);
                e.value = data_criteria[i];
              })
            }
          }
          this.criteria_label3 = [];

          for (var i = 0; i < labelsWithClass.length; i++) {
            console.log('labels for step 3',labelsWithClass[i]);
            this.criteria_label3.push(labelsWithClass[i].textContent.replace(/\*$/, ''));
          }
          console.log('criteria_label3',this.criteria_label3);
          this.companyJson();
        };
  
        nextbtn3.onclick = () => {
          document.getElementById("step3").style.visibility = "hidden";
          document.getElementById("step3").setAttribute("class", "heightzero");
          document.getElementById("step4").setAttribute("class", "d-block");
          document.getElementById("step4").style.visibility = "visible";

          var parentContainer = document.getElementById('step4');
          var elementsWithClass = parentContainer.getElementsByClassName('criteriaform');
          var labelsWithClass = parentContainer.getElementsByClassName('score_label');

          var formids = [];
          for (var i = 0; i < elementsWithClass.length; i++) {
            //this.formids.push(elementsWithClass[i].getAttribute('id'));

            console.log('elementswithclass____123',elementsWithClass[i]);
            var data = this.score_schema.find((item)=>{
              return Object.keys(item).includes(elementsWithClass[i].getAttribute('data-name'));
            })
            console.log('data_criteria__1234',Object.values(data[elementsWithClass[i].getAttribute('data-name')]));
            var data_criteria:string[] = Object.values(data[elementsWithClass[i].getAttribute('data-name')]);
            formids.push(elementsWithClass[i].getAttribute('id'));
            console.log('formids_step3',this.formids);
            for(var j = 0 ; j < formids.length ; j++){
              document.querySelector(`#step4 #${formids[i]}`).querySelectorAll('input').forEach((e,i)=>{
                console.log('e_3',e);
                e.value = data_criteria[i];
              })
            }
          }

          console.log('formids___3',this.formids);

          this.criteria_label4 = [];

          for (var i = 0; i < labelsWithClass.length; i++) {
            this.criteria_label4.push(labelsWithClass[i].textContent.replace(/\*$/, ''));
          }
          this.sectionJson();
        };
  
        nextbtn4.onclick = () => {
          this.additionalJson();
        }

        prevbtn2.onclick = () => {
          document.getElementById("step2").style.visibility = "hidden";
          document.getElementById("step2").setAttribute("class", "heightzero");
          document.getElementById("step1").setAttribute("class", "d-block");
          document.getElementById("step1").style.visibility = "visible";
        };
  
        prevbtn3.onclick = () => {
          document.getElementById("step3").style.visibility = "hidden";
          document.getElementById("step3").setAttribute("class", "heightzero");
          document.getElementById("step2").setAttribute("class", "d-block");
          document.getElementById("step2").style.visibility = "visible";
          var parentContainer = document.getElementById('step2');
          var elementsWithClass = parentContainer.getElementsByClassName('criteriaform');
          var labelsWithClass = parentContainer.getElementsByClassName('score_label');

          for (var i = 0; i < elementsWithClass.length; i++) {
            this.formids.push(elementsWithClass[i].getAttribute('id'));
          }

          this.criteria_label = [];

          for (var i = 0; i < labelsWithClass.length; i++) {
            this.criteria_label.push(labelsWithClass[i].textContent.replace(/\*$/, ''));
          }
          
        };
  
        prevbtn4.onclick = () => {
          document.getElementById("step4").style.visibility = "hidden";
          document.getElementById("step4").setAttribute("class", "heightzero");
          document.getElementById("step3").setAttribute("class", "d-block");
          document.getElementById("step3").style.visibility = "visible";

          var parentContainer = document.getElementById('step3');
          var elementsWithClass = parentContainer.getElementsByClassName('criteriaform');
          var labelsWithClass = parentContainer.getElementsByClassName('score_label');

          for (var i = 0; i < elementsWithClass.length; i++) {
            this.formids.push(elementsWithClass[i].getAttribute('id'));
          }

          this.criteria_label3 = [];

          for (var i = 0; i < labelsWithClass.length; i++) {
            this.criteria_label3.push(labelsWithClass[i].textContent.replace(/\*$/, ''));
          }
        };
  
        if (
          sessionStorage.getItem("role_name") == "Super Admin" ||
          sessionStorage.getItem("role_name") == "Project Manager"
        ) {
          document.querySelectorAll(".readonly").forEach(function (element) {
            element.setAttribute("readonly", "true");
          });
        }
  
        document.querySelectorAll(".hideonpreview").forEach(function (element) {
          element.setAttribute("class", "d-none");
        });   

    });
  } 

  personalJson() {
    let someDOMElement = document.getElementById("step1");
    this.jsonOutput = toJSON(someDOMElement);
    this.arrayDataPush["step1"] = this.jsonOutput;
    console.log("arraydatapush_first", this.arrayDataPush);

  }

  companyJson() {
    let someDOMElement = document.getElementById("step2");
    this.jsonOutput = toJSON(someDOMElement);
    this.arrayDataPush["step2"] = this.jsonOutput;
    console.log("arraydatapush_second", this.arrayDataPush);


      var formids2 = [];

      var parentElement = document.getElementById('step2');
      var elementClasses = parentElement.getElementsByClassName('criteriaform');

      for(var i = 0 ; i < elementClasses.length ; i++){
        formids2.push(elementClasses[i].getAttribute('id'));
      }

    this.step2criteria = [];
    this.step2filter = [];
    console.log('criteria_labels2',this.criteria_label);
    for(var i = 0 ; i < formids2.length ; i++){
      console.log('formids_3',formids2[i]);
      if(document.getElementById(formids2[i])!=null){
        // if(!this.step2criteria.includes(new form().getFormData(formids3[i],this.criteria_label3[i]))){
          this.step2criteria.push(new form().getFormData(formids2[i],this.criteria_label[i]));
        // }
      }
    }

      for(var i = 0 ; i < this.step2criteria.length ; i++){
        console.log('keys',Object.keys(this.step2criteria[i]));
        if(Object.keys(this.step2criteria[i])[0]!='undefined'){
          this.step2filter.push(this.step2criteria[i]);
        }
      }
      
      console.log('step2filter-----',this.step2filter);

  }

  sectionJson() {
    let someDOMElement = document.getElementById("step3");
    this.jsonOutput = toJSON(someDOMElement);
    this.arrayDataPush["step3"] = this.jsonOutput;
    console.log("arraydatapush_third", this.arrayDataPush);

    var formids3 = [];

    var parentElement = document.getElementById('step3');
    var elementClasses = parentElement.getElementsByClassName('criteriaform');

    for(var i = 0 ; i < elementClasses.length ; i++){
      elementClasses[i].setAttribute('id',`criteriaform3_${i}`)
      formids3.push(`criteriaform3_${i}`);
    }

    this.step3criteria = [];
    this.step3filter = [];
    for(var i = 0 ; i < formids3.length ; i++){
      console.log('formids_3',formids3[i]);
      if(document.getElementById(formids3[i])!=null){
        // if(!this.step2criteria.includes(new form().getFormData(formids3[i],this.criteria_label3[i]))){
          this.step3criteria.push(new form().getFormData(formids3[i],this.criteria_label3[i]));
        // }
      }
    }

    for(var i = 0 ; i < this.step3criteria.length ; i++){
      console.log('keys',Object.keys(this.step3criteria[i]));
      if(Object.keys(this.step3criteria[i])[0]!='undefined'){
        this.step3filter.push(this.step3criteria[i]);
      }
    }

    console.log('step3criteria',this.step3criteria);
    console.log('step3filter-----',this.step3filter);
 
  }
  additionalJson() {
    let someDOMElement = document.getElementById("step4");
    this.jsonOutput = toJSON(someDOMElement);
    this.arrayDataPush["step4"] = this.jsonOutput;
    console.log("arraydatapush_final", this.arrayDataPush);
    
    var formids4 = [];

    var parentElement = document.getElementById('step4');
    var elementClasses = parentElement.getElementsByClassName('criteriaform');

    for(var i = 0 ; i < elementClasses.length ; i++){
      elementClasses[i].setAttribute('id',`criteriaform4_${i}`);
      formids4.push(`criteriaform4_${i}`);
    }

    // for(var i = 0 ; i < elementClasses.length ; i++){
    //   formids4.push(elementClasses[i].getAttribute('id'));
    // }

    this.step4criteria = [];
    this.step4filter = [];
    for(var i = 0 ; i < formids4.length ; i++){
      console.log('formids_3',formids4[i]);
      if(document.getElementById(formids4[i])!=null){
        // if(!this.step2criteria.includes(new form().getFormData(formids3[i],this.criteria_label3[i]))){
          this.step4criteria.push(new form().getFormData(formids4[i],this.criteria_label4[i]));
        // }
      }
    }
    console.log('step4criteria1234',this.step4criteria);
    for(var i = 0 ; i < this.step4criteria.length ; i++){
      console.log('keys',Object.keys(this.step4criteria[i]));
      if(Object.keys(this.step4criteria[i])[0]!='undefined'){
        this.step4filter.push(this.step4criteria[i]);
      }
    }
    console.log('step4filter-----',this.step4filter);
    let score_schema = [];
    score_schema.push(...this.step2filter , ...this.step3filter , ...this.step4filter);
    console.log('score_schema_123',score_schema);
    let payload = {
      "id": this.formid,
      "form_name": new Date().getTime().toString(),
      "form_schema": this.arrayDataPush,
      "score_schema": score_schema
  };
  console.log('payload_form_123',payload);
  this.mentorService.saveRegistrationForm(payload).subscribe((res)=>{
    this.toastrService.success('Form updated successfully',res);
    this.router.navigate(['/admin/mentorformsall'])
    })
} 
  
  addQuestions(index:any) {
    console.log('index1234----',index);
    let parent = document.getElementById(`addhere_${index}`) as HTMLElement;
    parent.setAttribute('class','w-100');
    console.log("parent", parent);
    //parent.className+=" columnflex";
    let innerhtml = `
    <div class="row w-100 border border-5 border-secondary p-3 mb-4 removeinui">
    <div class="col-md-10">
    <input type="text" name="" id="" class="form-control my-2 label_question" placeholder="enter question">
    <label class="addlabel"></label>
    <input type="checkbox" class="scorable" value="scorable"> <span class="scorable_text">Scorable</span>
    <div class="answer"></div>
    <a class="ml-auto text-white bg-orange font-weight-bold float-right my-2 removequestion p-2 border-5">
    <i class="icon fa fa-minus text-white font-weight-bold mr-2"></i>
    Remove Question
    </a>
    </div>
    <div class="w-100 col-md-2">
    <select class="btn bg-transparent border-info selectfield">
      <option value="">Select Answer Type</option>
      <option value="textarea">Long Answer</option>
      <option value="text">Short Answer</option>
      <option value="radio">Radio</option>
      <option value="checkbox">Checkbox</option>
      <option value="file">File Upload</option>
      <option value="number">Enter Number</option>
      <option value="date">Select Date</option>
      <option value="select">Dropdown</option>
    </select>
    </div>
    </div>
    `;
    console.log('add_here__',$(`#addhere_${index}`));
    $(`#addhere_${index}`).append(innerhtml);

    document.querySelectorAll('.removeinui').forEach((element,i)=>{
      element.setAttribute('id',`removeinui_${i}`)
    })

    document.querySelectorAll('.removequestion').forEach((element,i)=>{
      element.setAttribute('id',`removequestion_${i}`);
      document.getElementById(`removequestion_${i}`).onclick = (e) => {
          e.preventDefault();
          document.getElementById(`removeinui_${i}`).remove();
        }
    })


    document.querySelectorAll(".selectfield").forEach((element, i) => {
      console.log("selectindex", i);
      console.log("element", element);
     

      element.addEventListener("change", ($event: any) => {
        console.log("hello_answer");
        document.querySelectorAll('.answer').forEach((element,i)=>{
          element.setAttribute("id",`answer${i}`);
        })
          this.test($event, i);
      }); 
    });

    document.querySelectorAll("[value = 'scorable']").forEach((e,i)=>{
      console.log('index_scorable',i);
      e.setAttribute('id',`scorable_${i}`)
        document.getElementById(`scorable_${i}`).onchange = (e:any) => {
          if(e.target.checked == true){
            document.querySelectorAll('.answer').forEach((element,i)=>{
              element.setAttribute("id",`answer${i}`);
            })
            document.getElementById(`answer${i}`).classList.remove('d-none');
            this.test(e,i)
          }
          else{
            document.getElementById(`answer${i}`).classList.add('d-none');
          }
        }
    })
    
    async function addIdtoLabel(step,index){
      console.log('step___1243',step);
      console.log('index_____1234',index);
      var parent = document.getElementById(step);
      var addLabel = parent.getElementsByClassName('addlabel');
      for(var i = 0 ; i < addLabel.length ; i++){
        addLabel[i].setAttribute('id',`addlabel_${index}`);
      }
    }

    async function addIdtoLabel3(index){
      var parent = document.getElementById('step3');
      var addLabel = parent.getElementsByClassName('addlabel');
      for(var i = 0 ; i < addLabel.length ; i++){
        addLabel[i].setAttribute('id',`addlabel_${index}`);
      }
    }
      var parent_label = document.getElementById('step2');
      var label_question = parent_label.getElementsByClassName('label_question');
      var step2labelids = [];
      for(var i = 0 ; i < label_question.length; i++){
        label_question[i].setAttribute('id',`label_question_${i}`);
        var index:any = i;
        step2labelids.push(`label_question_${i}`)
        console.log('index__label___',index);
        console.log('label_question_ind',label_question[i]);
        label_question[i].addEventListener('keyup',async($event)=>{
          console.log('index___1',index);
          console.log('hello from step2',document.getElementById(step2labelids[step2labelids.length-1]));
          var nextSibling = document.getElementById(step2labelids[step2labelids.length-1]).nextElementSibling;
          console.log('nextsibling',nextSibling);
          nextSibling.setAttribute('id',`addlabel_${step2labelids.length-1}`)
          //await addIdtoLabel('step2',step2labelids.length-1);
          await this.getlabelforpreview($event);
          document.querySelector(`#step2 #addlabel_${step2labelids.length-1}`).innerHTML = this.label;
        })
      }

      console.log('step2ids___',step2labelids);
      var parent_label3 = document.getElementById('step3');
      var label_question3 = parent_label3.getElementsByClassName('label_question');
      var step3labelids = [];
      for(var i = 0 ; i < label_question3.length; i++){
        label_question3[i].setAttribute('id',`label_question_3_${i}`);
        var index:any = i;
        step3labelids.push(`label_question_3_${i}`);
        label_question3[i].addEventListener('keyup',async($event)=>{
        var nextSibling = document.getElementById(step3labelids[step3labelids.length-1]).nextElementSibling;
        console.log('nextsibling3----',nextSibling);
        nextSibling.setAttribute('id',`addlabel_3_${step3labelids.length-1}`)
        //await addIdtoLabel('step3',index);
        await this.getlabelforpreview($event);
        document.getElementById(`addlabel_3_${step3labelids.length-1}`).innerHTML = this.label;
        })
      }

      var parent_label4 = document.getElementById('step4');
      var label_question4 = parent_label4.getElementsByClassName('label_question');
      var step4labelids = [];
      for(var i = 0 ; i < label_question4.length; i++){
        label_question4[i].setAttribute('id',`label_question_4_${i}`);
        var index:any = i;
        step4labelids.push(`label_question_4_${i}`);
        label_question4[i].addEventListener('keyup',async($event)=>{
          var nextSibling = document.getElementById(step4labelids[step4labelids.length-1]).nextElementSibling;
          console.log('nextsibling4----',nextSibling);
          nextSibling.setAttribute('id',`addlabel_4_${step4labelids.length-1}`)
          await this.getlabelforpreview($event);
          document.querySelector(`#step4 #addlabel_4_${step4labelids.length-1}`).innerHTML = this.label;
        })
      }

  }

  async getlabelforpreview(event:any){
    console.log('event__12345',event);
    console.log('label value -----',event.target.value);
    return new Promise ((resolve,reject)=>{
      this.label = event.target.value;
      console.log('label',typeof(this.label));
      resolve(this.label);
    })
  } 

  test(event: any, i: number) {
    console.log("from_test",i);
    console.log("label", this.label);
    var index = i;
    this.parent = document.getElementById(
      `answer${i}`
    ) as HTMLElement;
    console.log("parent123", this.parent);
    document.getElementById(`answer${i}`).classList.remove('d-none');
    if (event.target.value != "") {
      let childnode_label = document.createElement("label");
      childnode_label.innerHTML = this.label;
      console.log('parent',this.parent);
      this.parent.appendChild(childnode_label);
      if(event.target.value !='scorable'){
        if (event.target.value != "select") {
          if (event.target.value != "textarea") {
            if(event.target.value == 'radio'|| event.target.value == 'checkbox'){
              var index = i;
              console.log('index__123',index);
              let innerHTML = `
              <div id="divradio_test_${i}" class="d-flex">
              
              </div>
              <div id="placeinputhere_test_${i}"></div>
              <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
              <button class="btn btn-primary addmoreradio_test" type="button" id="addmoreradio_test_${i}">+</button>
              `;
        this.parent.innerHTML = innerHTML;
        document.querySelectorAll(".answer").forEach((element,i)=>{
          console.log('addoptions_index',i,`addmoreradio_test_${i}`);
          let count = 2
          console.log('radioelement',element);
          if(document.getElementById(`addmoreradio_test_${i}`)!=null){
            document.getElementById(`addmoreradio_test_${i}`).onclick = (e) => {
              e.preventDefault();
              console.log('addmoreradio',`addmoreradio_test_${i}`);
              let innerHTML = `
              <input type="text" placeholder="add option" class="my-2 form-control" id="inputoption_test_${i}">
              <button class="btn btn-primary submitoption_test d-none" id="submitoption_test_${i}">Submit</button>
              `;
              
              document.getElementById(`divradio_test_${i}`).innerHTML= innerHTML;
              console.log('inputid',`inputoption_test_${i}`);
                      
              console.log('doc_id',document.getElementById(`inputoption_test_${i}`))
        
              if(document.getElementById(`inputoption_test_${i}`)!=null){
                document.getElementById(`inputoption_test_${i}`).onkeyup= (event)=>{
                  let value = (<HTMLInputElement>document.getElementById(`inputoption_test_${i}`)).value;
                  let value_trim = value.replace(/ /g,'');
                  console.log('value1234',value);
                  if(value_trim != ""){
                    document.getElementById(`submitoption_test_${i}`).classList.remove('d-none');
                    document.getElementById(`submitoption_test_${i}`).className+=' d-block';
                  }
                  else{
                    document.getElementById(`submitoption_test_${i}`).classList.remove('d-block');
                    document.getElementById(`submitoption_test_${i}`).className+=' d-none';
                  }
                }
              }
              console.log('test_________1234',document.getElementById(`divradio_test_${i}`));
              document.getElementById(`submitoption_test_${i}`).onclick = (e) => {
                e.preventDefault();
                console.log('submitoption',`submitoption_test_${i}`);
                let span = document.createElement("span");
                let childnode_radio = document.createElement("input");
                let input = (<HTMLInputElement>document.getElementById(`inputoption_test_${i}`));
                if(input.value!=''){
                  span.innerText = input.value;
                  childnode_radio.setAttribute("type", event.target.value);
                  document.getElementById(`placeinputhere_test_${i}`).appendChild(childnode_radio);
                  document.getElementById(`placeinputhere_test_${i}`).appendChild(span);
                  childnode_radio.setAttribute('name',this.label);
                  input.value = '';
                  document.getElementById(`inputoption_test_${i}`).setAttribute('class','d-none');
                  document.getElementById(`submitoption_test_${i}`).setAttribute('class','d-none');
                }
              }
  
              
            }
    
        }
        else{
         
        }
        })
            }
            else {
              let innerHTML = `
  
              <input type="${event.target.value}" class="form-control" id="input_test_${i}" name="${this.label}">
              <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test"  value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
              <button class="btn btn-primary addmoreradio_test d-none" type = "button" id="addmoreradio_test_${i}">+</button>
              `;
              this.parent.innerHTML = innerHTML;
  
              // if(event.target.value == 'checkbox'){
              //   document.getElementById(`input_test_${i}`).setAttribute('name',`${this.label}[]`)
              // }
              // else{
              //   document.getElementById(`input_test_${i}`).setAttribute('name',`${this.label}`)
              // }
            }
  
            // document.querySelectorAll(".mandatory_test").forEach((element,i) => {
              //console.log('mandatory',document.getElementById(`mandatory_test_${i}`));
              // if(document.getElementById(`mandatory_test_${i}`)!=null){
                document.getElementById(`mandatory_test_${index}`).onchange = async($event) => {
                  var star = document.createElement('span');
                  star.textContent = "*";
                  document.querySelector(`#removeinui_${index} .addlabel`).insertAdjacentElement('afterend',star)
                  //console.log('clicked on mandatory');
                  await this.getchecked($event,index);
                  //console.log('mandatory_id',`mandatory_test_${index}`);
                  if(this.checked == true){
                    document.getElementById(`input_test_${index}`).setAttribute('required','true');
                  }
                  else if(this.checked == false){
                    document.getElementById(`input_test_${index}`).setAttribute('required','false');
                  }
                  //console.log('input_id',document.getElementById(`input_test_${i}`));
                }
              // }
     
            // });
  
          } else {
            let innerHTML = `
            <textarea class="form-control" id="input_${i}" name="${this.label}"></textarea>
            <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
            <button class="btn btn-primary addmoreradio_test d-none" id="addmoreradio_test_${i}">+</button>
            `
            this.parent.innerHTML = innerHTML;
  
            document.querySelectorAll(".mandatorytest").forEach((element,i) => {
              document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
                await this.getchecked($event);
                console.log('mandatory_id',`mandatory_test_${i}`);
                if(this.checked == true){
                  document.getElementById(`input_test_${i}`).setAttribute('required','true');
                }
                else if(this.checked == false){
                  document.getElementById(`input_test_${i}`).setAttribute('required','false');
                }
                console.log('input_id',document.getElementById(`input_test_${i}`));
              }
            });
  
          }
        } else {
          let innerHTML = `
            <select class="form-control" id="input_test_${i}" name="${this.label}">
              
            </select>
            <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
            <input type="checkbox" id="multiple_${i}" class="mandatory_test" ><span class="mandatorytext">Multiselect</span>
            <button class="btn btn-primary addmoreoption_test" type="button" id="addmoreoption_test_${i}">+Add</button>
          `
            this.parent.innerHTML = innerHTML;
  
            document.getElementById(`multiple_${i}`).onclick = (e:any) => {
              e.preventDefault();
              if(e.target.checked == true){
                document.getElementById(`input_test_${i}`).setAttribute('multiple','');
                //document.getElementById(`input_test_${i}`).setAttribute('name',`${this.label}[]`);
              }
              else{
                document.getElementById(`input_test_${i}`).removeAttribute('multiple');
                //document.getElementById(`input_test_${i}`).setAttribute('name',`${this.label}`);
              }
              
            }
  
            document.querySelectorAll(".mandatory_test").forEach((element,i) => {
              if(document.getElementById(`mandatory_test_${i}`)!=null){
                document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
                  await this.getchecked($event);
                  console.log('mandatory_id',`mandatory_test_${i}`);
                  if(this.checked == true){
                    document.getElementById(`input_test_${i}`).setAttribute('required','true');
                  }
                  else if(this.checked == false){
                    document.getElementById(`input_test_${i}`).setAttribute('required','false');
                  }
                  console.log('input_id',document.getElementById(`input_test_${i}`));
                }
              }
    
            });
  
            document.querySelectorAll(".answer").forEach((element,i)=>{
              console.log('addoptions_index',i);
              let count = 2
              console.log('radioelement',element);
              if(document.getElementById(`addmoreoption_test_${i}`)!=null){
              document.getElementById(`addmoreoption_test_${i}`).onclick = () => {
                // let option = document.createElement("option");
                // option.text = `option ${count++}`;
                // option.value = `option ${count++}`
     
                // document.getElementById(`input_test_${i}`).appendChild(option);

                var userInput = prompt("Please enter a value:");
                let option = document.createElement("option");
                option.text = userInput;
                option.value = userInput;
                document.getElementById(`input_test_${i}`).appendChild(option);
                
              }
            }
            else{
            
            }
            })
  
        }
      }
 
      else {
        let innerHTML = `
        <select class="form-control" id="input_test_${i}" name="${this.label}">
          
        </select>
        <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
        <input type="checkbox" id="multiple_${i}" class="mandatory_test" ><span class="mandatorytext">Multiselect</span>
        <button class="btn btn-primary addmoreoption_test" type="button" id="addmoreoption_test_${i}">+Add</button>
      `
        this.parent.innerHTML = innerHTML;

        document.getElementById(`multiple_${i}`).onclick = (e:any) => {
          e.preventDefault();
          if(e.target.checked == true){
            document.getElementById(`input_test_${i}`).setAttribute('multiple','');
            //document.getElementById(`input_test_${i}`).setAttribute('name',`${this.label}[]`);
          }
          else{
            document.getElementById(`input_test_${i}`).removeAttribute('multiple');
            //document.getElementById(`input_test_${i}`).setAttribute('name',`${this.label}`);
          }
          
        }

        document.querySelectorAll(".mandatory_test").forEach((element,i) => {
          if(document.getElementById(`mandatory_test_${i}`)!=null){
            document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
              await this.getchecked($event);
              console.log('mandatory_id',`mandatory_test_${i}`);
              if(this.checked == true){
                document.getElementById(`input_test_${i}`).setAttribute('required','true');
              }
              else if(this.checked == false){
                document.getElementById(`input_test_${i}`).setAttribute('required','false');
              }
              console.log('input_id',document.getElementById(`input_test_${i}`));
            }
          }

        });

        document.querySelectorAll(".answer").forEach((element,i)=>{
          console.log('addoptions_index',i);
          let count = 2
          console.log('radioelement',element);
          if(document.getElementById(`addmoreoption_test_${i}`)!=null){
          document.getElementById(`addmoreoption_test_${i}`).onclick = () => {
            // let option = document.createElement("option");
            // option.text = `option ${count++}`;
            // option.value = `option ${count++}`
 
            // document.getElementById(`input_test_${i}`).appendChild(option);

            if(document.getElementById('step2')!=null){
              var parent2 = document.getElementById('step2');
              var labels2 = parent2.getElementsByTagName('label');
              console.log('labels in step2',labels2);
              // for(var i = 0 ; i < labels2.length ; i++){
              //   if(!this.criteria_label.includes(labels2[i].textContent.replace(/\*/g, ''))){
              //    this.criteria_label.push(labels2[i].textContent.replace(/\*/g, ''));
              //   }
              // }
              if(this.label != undefined){
                if(!this.criteria_label.includes(this.label)){
                  this.criteria_label.push(this.label);
                }
              }
              console.log('criteria_label2',this.criteria_label);
            }
            if(document.getElementById('step3')!=null){
              if(!this.criteria_label3.includes(this.label)){
                this.criteria_label3.push(this.label);
              }
              console.log('criteria_label3',this.criteria_label3);
            }

            if(document.getElementById('step4')!=null){
              if(!this.criteria_label4.includes(this.label)){
                this.criteria_label4.push(this.label);
              }
              console.log('criteria_label4',this.criteria_label4);
            }
            
            var userInput = prompt("Please enter a value:");
            let option = document.createElement("option");
            option.text = "Select an option";
            option.text = userInput;
            option.value = userInput;
            console.log('add score inputs',i);
            document.getElementById(`input_test_${i}`).appendChild(option);

            if(document.getElementById(`criteriaform_${i}`)==null){
              console.log('ids__123',i)
              // if(!this.formids.includes(`criteriaform_${index}`)){
                this.formids.push(`criteriaform_${i}`);
                console.log('formids_1234',this.formids);
              // }
              console.log('formids----',this.formids);
              var form = document.createElement('form');
              form.id = `criteriaform_${i}`;
              form.setAttribute('class','criteriaform');
              form.setAttribute('data-name',this.label);
              var input = document.createElement('input');
              input.type = 'number';
              input.name = option.value;
              input.placeholder = `Please add score value for ${option.value}`;
              input.setAttribute('class','criteria_input form-control');
              form.appendChild(input);
              form.appendChild(document.createElement('br'));
              document.getElementById(`answer${i}`).appendChild(form);
            }
          else{
              var input = document.createElement('input');
              input.type = 'number';
              input.name = option.value;
              input.placeholder = `Please add score value for ${option.value}`;
              input.setAttribute('class','criteria_input form-control');
              document.getElementById(`criteriaform_${i}`).appendChild(input);
              document.getElementById(`criteriaform_${i}`).appendChild(document.createElement('br'));
          }
          }
        }
        else{
        
        }
        })
      }
    }
  }
  async getchecked(event,index?){
    console.log('event_value_1',event.target.checked);
    console.log('event_value_2',event.target.value);
    console.log('index for required',index);
    var checked;
    if(event.target.checked != undefined){
      checked = event.target.checked;
      console.log('checked123',checked);
    }
    var obj = {}
    obj[document.querySelector(`#removeinui_${index} .addlabel`).textContent.replace(/\*/g, '')] = {
      "required":false,
      "pattern": ""
    }
    if(checked){
      console.log('checked_1');
      obj[document.querySelector(`#removeinui_${index} .addlabel`).textContent.replace(/\*/g, '')]['required'] = true;
    }
    else{
      console.log('checked_2');
      obj[document.querySelector(`#removeinui_${index} .addlabel`).textContent.replace(/\*/g, '')]['required'] = false;
    }
    if(this.validation.length!=0){
      var oldvalue = this.findByKey(this.validation,document.querySelector(`#removeinui_${index} .addlabel`).textContent.replace(/\*/g, ''));
      console.log('oldvalue____123',oldvalue);
    }
    
  if(this.validation.length==0){
      this.validation.push(obj)
    }
    else{
      if(this.isKeyPresent(this.validation,document.querySelector(`#removeinui_${index} .addlabel`).textContent.replace(/\*/g, ''))){
        console.log('key_new');
        for(var i = 0 ; i < this.validation.length ; i++){
          if(this.validation[i][document.querySelector(`#removeinui_${index} .addlabel`).textContent.replace(/\*/g, '')]!=undefined){
            this.validation[i][document.querySelector(`#removeinui_${index} .addlabel`).textContent.replace(/\*/g, '')]["required"] = obj[document.querySelector(`#removeinui_${index} .addlabel`).textContent.replace(/\*/g, '')]["required"];
          }
        }

      }
      else{
        console.log('key_old');
        this.validation.push(obj);
      }
    }
    console.log('validation array',this.validation);
    return new Promise((resolve,reject)=>{
      this.checked = event.target.checked;
      resolve(this.checked);
    })
//
  }

  findByKey(array, key) {
    return array.find(obj => obj.hasOwnProperty(key));
  }

  replaceValueByKey(array, key, oldValue, newValue) {
    array.forEach(obj => {
        if (obj[key] === oldValue) {
            obj[key] = newValue;
        }
    });
  }

  isKeyPresent(array, key) {
    for (let obj of array) {
      if (obj.hasOwnProperty(key)) {
          return true; // Key is present in at least one object
      }
  }
  return false; // Key is not present in any object
}
  converttohtml() {
    return new Promise((resolve, reject) => {
      console.log("before convert to html");
      var step1: any;
      var step2: any;
      var step3: any;
      var step4: unknown;
      this.mentorService
        .getRegistrationForm(this.formid)
        .subscribe((res) => {
          console.log("resform1234", res);

            step1 = res.form_schema.step1;
            step2 = res.form_schema.step2;
            step3 = res.form_schema.step3;
            step4 = res.form_schema.step4;
            
            console.log('step1',step1);
            console.log('step2',step2);
            console.log('step3',step3);
            console.log('step4',step4);

            let parent1 = document.getElementById("placehere1");
            let DOMDocumentFragment1 = toDOM(step1);
            console.log("DOMDoc", DOMDocumentFragment1);
            parent1.parentNode.appendChild(DOMDocumentFragment1);

            let parent2 = document.getElementById("placehere2");
            let DOMDocumentFragment2 = toDOM(step2);
            console.log("DOMDoc", DOMDocumentFragment2);
            parent2.parentNode.appendChild(DOMDocumentFragment2);

            let parent3 = document.getElementById("placehere3");
            let DOMDocumentFragment3 = toDOM(step3);
            console.log("DOMDoc", DOMDocumentFragment3);
            parent3.parentNode.appendChild(DOMDocumentFragment3);

            let parent4 = document.getElementById("placehere4");
            let DOMDocumentFragment4 = toDOM(step4);
            console.log("DOMDoc", DOMDocumentFragment4);
            parent4.parentNode.appendChild(DOMDocumentFragment4);
            resolve(step4);
        });
    });
  }

  handleCancel(){
    this.router.navigate(['/admin/mentorformsall'])
  }
}
