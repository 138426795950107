import { AfterViewInit, Component, OnInit } from "@angular/core";
import * as XLSX from "xlsx";
import { StartupService } from "../../services/startup.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ExportToCsv } from "export-to-csv";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChallengeService } from "../../services/challenge.service";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { states } from "../../../assets/js/states";
import { MatDialog } from "@angular/material/dialog";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { ExcelService } from "../../services/excel.service";
import { StateService } from "../../services/state.service";
import { create } from "domain";
import { throws } from "assert";


const styles = {
  header: {
    fontSize: 18,
    bold: true,
  },
  subheader: {
    fontSize: 14,
    bold: true,
  },
  body: {
    fontSize: 12,
  },
};

@Component({
  selector: "ngx-startups",
  templateUrl: "./startups.component.html",
  styleUrls: ["./startups.component.scss"],
})
export class StartupsComponent implements OnInit , AfterViewInit{
  data: any;
  items = [{ title: "PDF" }, { title: "EXCEL" }];
  list: any = [];
  modalUpdateForm: FormGroup;
  action: { status: string };
  inValidMessage: boolean;
  alldomains: any;
  groupbydomains: any;
  dropdownList = [];
  sectors: any = [];
  dropdownList1 = [];
  tempdata = [];
  selectedItems1 = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings_t: IDropdownSettings = {};
  dropdownSettings_s: IDropdownSettings = {};
  dropdownSettings_scheme: IDropdownSettings = {};
  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectcity: string = "";
  selectstate: any = [];
  selectdomain: any = [];
  selectsector: any = [];
  selectstatus: any = [];
  selectedItems: any[];
  selectedStates: any[];
  selectedindex:number = 0;
  dsApproved = true;
  dsRejected = true;
  dsPending = true;
  viewallcount:any;
  // dropdownstates: any[] = [
  //   'Karnataka','UP','Telengana', 'Andhra Pradesh'
  // ];
  dropdownstates: any = [];
  selectedStatus: any[];
  dropdownstatus: any[] = [ "APPROVED","PENDING", "REJECTED"];
  dropdownschemes:any[] = [
    { id: 1, name: "TIDE 2.0" }, 
    { id: 2, name: "SAMRIDH" },
    { id: 3, name: "OTHER MEITY SCHEME" },
    { id: 4, name: "GENESIS" },
    { id: 5, name: "NON MEITY SCHEME" },
    { id: 6, name: "NGIS" }
  ];
  selectedScheme:any;
  originalData: any[];
  public domain = [];
  public sector = [];
  filteredlist = [];
  filterMode: boolean = true;
  resetMode: boolean = false;
  p: number = 1;
  filterValue: any;
  mat_data: string;
  totalItems: number;
  StartupList:any;
  allStates: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  pageSize = 10; 
  loading: boolean = true; 
  activeFilter: string | null = null; 
  filterValue1:any;
  viewback: any;
  // dsFiltervalue: string;
  // dsPending:boolean = true;
  // dsApproved: boolean = true;
  // dsRejected: boolean = true;
  // dsAll: boolean = true;
  schemeSelected:boolean = false;
  schemesData:any;
  count: any;
  test:number = 4000;
  pagelinks:any = [];
  initial_pages: any = [];
  dots: string;
  remaining_pages: any = [];
  approved_count:any;
  rejected_count:any;
  pending_count:any;
  page_index:number = 0;
  pageno:any = 0;
  totalpages: number;
  datafordownload: any;
  section:any;

  constructor(
    private startupService: StartupService,
    private toastrService: NbToastrService,
    private router: Router,
    private fb: FormBuilder,
    private challengeService: ChallengeService,
    private _route:ActivatedRoute,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private excelService:ExcelService,
    private statesservice : StateService



  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);


    // this.dsFiltervalue = this._route.snapshot.queryParamMap.get('filter');
   
    // if (localStorage.getItem('status')) {
    //   // If it exists, assign its value to this.filterValue
    //   this.filterValue = localStorage.getItem('status');
    // }   
    this.activeFilter = this.filterValue;
    if(localStorage.getItem('viewback'))
    {
      this.viewback = localStorage.getItem('viewback');
    }
    

  }

  ngOnInit(): void {
    
    // Check if the page has been reloaded
if (!sessionStorage.getItem("pageReloaded")) {
  // This code will only run once after the page is reloaded
  console.log("Page loaded for the first time in this session.");

  // Set a flag in sessionStorage
  sessionStorage.setItem("pageReloaded", "true");
} else {
  console.log("Page reloaded.");
  localStorage.removeItem("viewback");
}

    this.selectedItems = [];
    this.selectedStates = [];
    this.selectedStatus = [];
    
 
    this.getStates()
    this.onItemSelectDomain(0);
    this.getDomains();
    if (this.viewback == 'true') {          
      this.backfilter();   
      if(localStorage.getItem('countdata'))
      {
        const storedJsonString = localStorage.getItem('countdata');
        const storedObject = JSON.parse(storedJsonString);
        console.log("count data is==>", storedObject);
        console.log(storedObject.approvedData)
        this.approvedCount = storedObject.approvedData;
        this.rejectedCount = storedObject.rejectedData;
        this.pendingCount = storedObject.pendingData;
       
  
      }  
      // localStorage.removeItem('viewback');
    } if (this.filterValue != null) {
      console.log('here is the filtered set');
      this.filterItem(this.filterValue);
      localStorage.removeItem('viewback');
      localStorage.removeItem('filterArray');
      this.selectedStates = [];
      this.selectedStatus = this.filterValue;
      this.selectcity = '';
      this.selectedItems = [];
      this.activeFilter = this.filterValue;
      this.viewback = 'true'
      
      

    } else {
      
      this.listAllStartups();
    }
    // if(this.filterValue != null) {
    //   this.filterItem(this.filterValue);
    //   // this.filterValue = null;
    // }
    // else{
    //   this.listAllStartups();
      
    // }

    // if (localStorage.getItem('filterArray')) {
    //   alert("adsada");
    //   debugger;
    //   console.log("my array is => ", this.myArray)
    //   const getArray = localStorage.getItem('filterArray');
    //   const parsedArray = JSON.parse(getArray);
    //   this.myArray.push(parsedArray);      
    //   console.log("my array is => ", this.myArray);
    //   this.viewback = true;
    //   this.backfilter();
      
    // }
    // this.getAllStartUpData();
   

    this.modalUpdateForm = this.fb.group({
      modalStartUpName: [""],
      modalDocumentName: [""],
    });

    
    this.dropdownList = [];
    // this.dropdownstates = states;
  
    this.selectedItems1 = [];
    this.filteredlist = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_t = {
      singleSelection: true,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_scheme = {
        singleSelection: false,
        idField: "id",
        textField: "name",
    };
    // this.exportData(this.startupdata)
    // const startupDataArray = this.startupdata1; // Array containing multiple startupData objects
    // this.exportData(startupDataArray);
    // pagination page redirection code
    this._route.queryParams.subscribe((params) => {
      // Use the 'page' parameter from the URL or default to 1
      this.p = +params['page'] || 1;
      // Load data for the current page
      this.loadData(this.p);
    });
     // pagination page redirection code

  }

  schemeChanged(event) {
    this.loading = true;
    this.startupService.getSchemesList(this.selectedScheme.map((scheme:any) => scheme.id)).subscribe(res => {
        this.loading = false;
        this.schemesData = res.results;
        this.data = this.schemesData;
    });
    this.schemeSelected = true
  }

  ngOnDestroy(): void{
    // remove style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = ''; 
      htmlElement.style.position = '';
    }
    localStorage.removeItem('viewback');
    // this.dsFiltervalue = '';
    // this.dsAll = false;
    // this.dsApproved = false;
    // this.dsPending = false;
    // this.dsRejected = false
    
    
   
      // remove style for ui break on scroll
 }

//  pagination page redirection code
onPageChange(page: number): void {
  // Update the current page and navigate to the new URL
  this.p = page;
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: { page: page },
    queryParamsHandling: 'merge',
  });
 
  this.loadData(page);
}
private loadData(page: number): void {
  // Implement your logic to load data based on the page
  console.log('Loading data for page', page);
}
//  pagination page redirection code

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Startup Report','Startup-Data');
  }
  // state
  getStates() {
    this.statesservice.getallstates().subscribe((res: any) => {
      this.allStates = res.results;
      console.log("all states =>", this.allStates);
      this.dropdownstates = this.allStates
     
   
    });
  }
  // state

 

  listAllStartups() {
    console.log('hello from startups');
    let data={
      search_entity: "STARTUP",
      "page": 0, "page_size": 10,
    }
    this.loading = true;
    this.startupService.getStartupsList(data).subscribe((res: any) => {
      this.data = res.results;
      this.StartupList=res.results;
      this.count = +res.total_search_results_count;
      this.approved_count = res.approved_count;
      this.rejected_count = res.rejected_count;
      this.pending_count = res.pending_count;
      this.originalData = this.data;
      this.totalItems = +res.total_search_results_count;
      for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
        this.pagelinks.push(i);
    }

      // Example dynamic pagination generation
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages; // Replace with your dynamic value
    this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
    // var paginationList = document.getElementById('pagination-list');
    // var paginationHTML = '';

    // Display first 10 pages
    // for (var i = 1; i <= Math.min(totalPages, 10); i++) {
    //     this.initial_pages.push(i);
    //     //paginationHTML += `<li class="mr-2"><a class="paginationlink" id = "paginate_${i}"> ${i} </a></li>`;
    // }
    // Add ellipsis if there are more pages
    if (totalPages > 10) {
        this.dots = '...'
        //paginationHTML += '<li class="mr-2"><span>...</span></li>';
    }

    // Display next set of pages after ellipsis
    for (var i = Math.max(11, totalPages - 10 + 1); i <= totalPages; i++) {
        this.remaining_pages.push(i);
        //paginationHTML += `<li class="mr-2"><a > ${i} </a></li>`;
    }

    // paginationList.innerHTML = paginationHTML;
        // Update status counts
        //this.updateStatusCounts(this.originalData);
        this.loading = false;
        this.onPageChange(1) 

    });
  }

  async listAllStartupsforDownload() {

    return new Promise((resolve,reject)=>{
      let data= {
        filters: this.myArray,
        search_entity: "STARTUP",
        "page": 0, "page_size": 10000
  
      }
      this.loading = true;
      this.startupService.getStartupsList(data).subscribe((res: any) => {
        this.datafordownload = res.results;
        resolve(this.datafordownload);
      });
    })
  }
  ngAfterViewInit(){
    document.querySelectorAll('.paginationlink').forEach((element , i)=>{
    })
  }

  paginate_startup(pageno) {
    this.pageno = pageno
    console.log('pageno----',this.pageno);
    this.selectedindex = pageno-1;
    console.log('selectedindex-----',this.selectedindex)

    let data={
        filters: this.myArray,
        search_entity: "STARTUP", 
        "page": pageno-1, "page_size": 10,
      }

    this.loading = true;
    this.startupService.getStartupsList(data).subscribe((res)=>{
      this.data = res.results
      console.log('data----');
      console.log(this.data);
      this.loading = false;
    })
  }
  getCountsByStatus(startups) {
    const statusCounts = {};

    startups.forEach((startup) => {
      const status = startup.status;

      if (status) {
        if (statusCounts.hasOwnProperty(status)) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      }
    });
   

    return statusCounts;
  }
  updateStatusCounts(data) {
    const statusCounts = this.getCountsByStatus(data);  

    // Separate the counts
    this.approvedCount = statusCounts["APPROVED"] || 0;
    this.rejectedCount = statusCounts["REJECTED"] || 0;
    this.pendingCount = statusCounts["PENDING"] || 0;
  }


  async downloadStartupList(){
    if(this.schemeSelected) {
        console.log('in if download');
        this.exportScheme(this.schemesData);
    } else {
        console.log('in else download');
        // const startupDataArray = this.originalData; // Array containing multiple startupData objects
        let downloaddata = await this.listAllStartupsforDownload();
        this.exportData(downloaddata);
        // this.exportAsXLSX(this.data)
    }
  }



  exportScheme(dataArray:any[]) {
    const invalid = [undefined,null,'null',''];
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };

  
  const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
 
  const filename = `StartupReport_${formattedDate}.xlsx`;

    const dataToExport = dataArray.map((d) => ({    
        'Startup name': d.name,
         'Schemes': d.entitytype,
        'Founder name' :d.foundername,
               'Phone': d.mobile,
        'Founder email': d.email,
        'Login email':d.loginemail,
        'Supported agency': d.supportedagency,
        'Supported agency website': d.supportedagencywebsite,
        entitytype: d.entitytype,
        'Entity Type': d.entityname,
        Domain: d.domain ? (Array.isArray(d.domain) ? d.domain.join(', ') : d.domain) : '-',
      City: d.city ? d.city : '-',
      State:d.state ? d.state : '-',
      Country:d.country ? d.country : '-',
      Status: d.status ? d.status : '-',
      Stage : d?.startup_stage ? d?.startup_stage : '-',
       'Suppoted Agency website': d.supportedagencywebsite,
        'Availed MSH Scheme':d.is_availed_scheme ? 'yes' : 'No',
        Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
        'Company Logo': d.profile_image && d.profile_image[0]?.url ? d.profile_image[0].url : '-',
    
     'First Name':d.first_name ?d.first_name:'-' ,
    'Last Name':d.last_name ? d.last_name :'-',
    
       'Zip code' :d.zipcode? d.zipcode :'-',
      'Mobile 1':d.mobile? d.mobile :'-',
      'Mobile 2':d.contact_info.mobile2? d.contact_info.mobile2 :'-',
   

      'Website Url':d.website_url ? d.website_url : '-',
      Description: d.short_description ? d.short_description.slice(0, 1000) : '-',
      
    
   
       'Part of which scheme': d.entitytype ? d.entitytype: '-',
       'Name of the Agenecy': d.supportedagency ? d.supportedagency:'-',
       
      'CIN No':d.cin_no ? d.cin_no : '-',
      'Funding Status':d.funding_status ? d.funding_status:'-',

      'Funding Agency Name':invalid.includes(d.funding_organization_name)? '-' : d.funding_organization_name,
      'Funding Amount(₹)(in Lakhs)':d.funding_amount ? d.funding_amount : '-',
      'Documents': d.documents ? (Array.isArray(d.documents) ? d.documents.map(info => `${info.file_name} URL: ${info.url}`).join('\n') : '-') : '-',
    
      'Market Product Name': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.productName}`).join('\n') : '-',
      'Market Product Detail': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.productDetail}`).join('\n') : '-',
      'Market Product CIN': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.cinNumber}`).join('\n') : '-',
     
    'Opportunity Name': Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `Opportunity Name: ${info.opportunity_Name}`).join('\n') : '-',
'Opportunity Details': Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `Opportunity Name: ${info.opportunity_Detail.slice(0, 1000)}`).join('\n') : '-',
      

     
      'Round Date': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Date}`).join('\n') : '-',
      'Round Amount': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Amount}`).join('\n') : '-',
      'Round Details': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Details.slice(0, 1000)}`).join('\n') : '-',
      'Round Valuation': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Valuation.slice(0, 1000)}`).join('\n') : '-',
      
      'Patents': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.patents}`).join('\n') : '-',
      'Copyrights': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.copyRights}`).join('\n') : '-',
      'Trademarks': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.tradeMarks}`).join('\n') : '-',
      'Trade Secrets': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.tradeSecrets}`).join('\n') : '-',

      'CEO Name': d.ceorname ? d.ceorname:'-',
      'CEO Mobile':d.ceophoneno ? d.ceophoneno :'-' , 
      'CEO Email': d.ceoemail? d.ceoemail: '-' ,
      'CEO Experience': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_exp : '-') : '-',
      'CEO LinkedIn': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).linkedinProfile : '-') : '-',
      
      'Team Size': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.filter(info => info.team_meb_designation !== 'CEO').length : '-',
      'Team Details': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.filter(info => info.team_meb_designation !== 'CEO').map(info => `Name: ${info.team_meb_name}, Mobile: ${info.team_meb_mob}, Email: ${info.team_meb_email}, Experience: ${info.team_meb_exp},Designation: ${info.team_meb_designation},Linkedin_Profile: ${info.linkedinProfile}`).join('\n') : '-', //
 
      Facebook: d?.social_info?.facebook ? d.social_info.facebook : '-',
      Instagram: d?.social_info?.instagram ? d.social_info.instagram : '-',
      Twitter: d?.social_info?.twitter ? d.social_info.twitter : '-',
      LinkedIn: d?.social_info?.linkedIn ? d.social_info.linkedIn : '-',
      Youtube: d?.social_info?.youtube ? d.social_info.youtube : '-',
      
      
      'Created At': d?.created_at ? new Date(d?.created_at) : '-',
      'Last Login Date':d?.updated_at ?new Date(d.updated_at) : '-',
      'Last Login Time':d?.updated_at? formatTime(new Date(d.updated_at)):'-',
      'Approved By':d.approved_by ? d.approved_by : '-',
   

   
     
    })).reduce(function(r,a) {
        r[a.entitytype] = r[a.entitytype] || [];
        r[a.entitytype].push(a);
        r[a.entitytype].map(item => {
            delete item.entitytype;
            return item;
        })
        return r;
    }, Object.create(null));

    this.excelService.exportExcelFileWithSheet(dataToExport,filename);
  }

  
  
//   exportData(dataArray: any[]) {    
//     const data = dataArray.map((d) => ({
//       Name: d.name,
//       Domain: d.domain,
//       Sector: d.sector,
//       City: d.city,
//       State:d.state,
//       Country:d.country,
//       websiteUrl:d.website_url,
//       Description: d.short_description,
//       Status: d.status,
//       ApprovedBy:d.approved_by,
//       CinNo:d.cin_no,
//       FundinAgencyName:d.funding_organization_name,
//       FundingAmount:d.funding_amount,
//       GoToMarket:Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `Product Name= ${info.productName},Product Detail= ${info.productDetail},Cin Number=${info.cinNumber}`).join(', ') : '',
//       OpportunityDetails:Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `OpportunityName=${info.opportunity_Name},OpportunityDetail= ${info.opportunity_Detail}`).join(', ') : '',
//       FunddingRounds:Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `Round Date=${info.round_Date},Round Detail= ${info.round_Details},Round Amount=${info.round_Amount},Round Valuation= ${info.round_Valuation}`).join(', ') : '',
//       IntellectualProperty:Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `Patents=${info.patents},Copyrights= ${info.copyRights},TradeMarks=${info.tradeMarks},Trade Secreats= ${info.tradeSecrets}`).join(', ') : '',
//       TeamDetails:Array.isArray(d?.team_info?.team_info) ? d?.team_info?.team_info.map(info => `Name=${info.team_meb_name},Mobile= ${info.team_meb_mob},Email=${info.team_meb_email},Experience= ${info.team_meb_exp},Description=${info.team_meb_designation},Linkedin Profile= ${info.linkedinProfile}`).join(', ') : '',      
// //       SocialInfo: [
// //   d?.social_info?.facebook ? `Facebook=${d.social_info.facebook}` : '',
// //   d?.social_info?.instagram ? `Instagram=${d.social_info.instagram}` : '',
// //   d?.social_info?.twitter ? `Twitter=${d.social_info.twitter}` : '',
// //   d?.social_info?.linkedIn ? `LinkedIn=${d.social_info.linkedIn}` : '',
// //   d?.social_info?.youtube ? `Youtube=${d.social_info.youtube}` : '',
// //   d?.social_info?.koo ? `Koo=${d.social_info.koo}` : ''
// // ]
// //   .filter(item => item !== '') // Exclude empty items
// //   .join(', '),
//       SocialInfo: d?.social_info ? `Facebook=${d.social_info.facebook}, Instagram=${d.social_info.instagram},Twitter=${d.social_info.twitter},LinkedIn=${d.social_info.linkedIn},Youtube=${d.social_info.youtube},Koo=${d.social_info.koo}` : '',
//       'Profile Email': d?.contact_info?.email,
//       'Login Email':d?.login_email,
//       'Updated_at':new Date(d?.updated_at),
      
     
//     }));
//     this.exportAsXLSX(data)
//     // this.generateExcelFile(data, "myData.xlsx");
//     console.log("Export:", data);
//   }
  
exportData(dataArray: any) { 
  const invalid = [undefined,null,'null',''];
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };
  const data = dataArray.map((d) => {
    // for(let i = 0; i < dataArray.length; i++){
    //   console.log( i, 'CEODetail', d?.team_info.teamInfo.filter(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ));
    // }
    // var CEODetails = d?.team_info?.teamInfo.filter(item => item?.team_meb_designation == "CEO" || item?.team_meb_designation == "ceo");
    
    const result: any = {
      // link: 'https://google.com',
      Name: d.name ? d.name : '-',
      Domain: d.domain ? (Array.isArray(d.domain) ? d.domain.join(', ') : d.domain) : '-',
      Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
      'Company Logo': d.profile_image && d.profile_image[0]?.url ? d.profile_image[0].url : '-',
    
     'First Name':d.contact_info.name ?d.contact_info.name:'-' ,
     'Last Name':d.contact_info.lastName ? d.contact_info.lastName :'-',
      City: d.city ? d.city : '-',
      State:d.state ? d.state : '-',
      Country:d.country ? d.country : '-',
      'Zip code' :d.contact_info.zipCode? d.contact_info.zipCode :'-',
      'Mobile 1':d.contact_info.mobile? d.contact_info.mobile :'-',
      'Mobile 2':d.contact_info.mobile2? d.contact_info.mobile2 :'-',

      'Website Url':d.website_url ? d.website_url : '-',
      Description: d.short_description ? d.short_description.slice(0, 1000) : '-',
      Status: d.status ? d.status : '-',
      // 'Approved By':d.approved_by ? d.approved_by : '-',
      Stage : d?.startup_stage ? d?.startup_stage : '-',
       'Part of which scheme': d.scheme_names ? d.scheme_names.join(',') : '-',
       'Name of the Agenecy': d.agency_names ? d.agency_names.join(','):'-',
        'Availed MSH Scheme':d.is_availed_scheme ? 'yes' : 'No',
      'CIN No':d.cin_no ? d.cin_no : '-',
      'Funding Status':d.funding_status ? d.funding_status:'-',

      'Funding Agency Name':invalid.includes(d.funding_organization_name)? '-' : d.funding_organization_name,
      'Funding Amount(₹)(in Lakhs)':d.funding_amount ? d.funding_amount : '-',
      'Documents': d.documents ? (Array.isArray(d.documents) ? d.documents.map(info => `${info.file_name} URL: ${info.url}`).join('\n') : '-') : '-',
      // GoToMarket:Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `Product Name= ${info.productName},Product Detail= ${info.productDetail},Cin Number=${info.cinNumber}`).join(', ') : '-',
      'Market Product Name': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.productName}`).join('\n') : '-',
      'Market Product Detail': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.productDetail}`).join('\n') : '-',
      'Market Product CIN': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.cinNumber}`).join('\n') : '-',
      // 'Opportunity Details':Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `OpportunityName=${info.opportunity_Name},OpportunityDetail= ${info.opportunity_Detail}`).join('\n') : '-',
      'Opportunity Name': Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `Opportunity Name: ${info.opportunity_Name}`).join('\n') : '-',
      'Opportunity Details': Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `Opportunity Name: ${info.opportunity_Detail.slice(0, 1000)}`).join('\n') : '-',
      // 'Funding Rounds':Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `Round Date=${info.round_Date},Round Detail= ${info.round_Details.slice(0, 1000)},Round Amount=${info.round_Amount},Round Valuation= ${info.round_Valuation}`).join('\n') : '-',
      'Round Date': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Date}`).join('\n') : '-',
      'Round Amount': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Amount}`).join('\n') : '-',
      'Round Details': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Details.slice(0, 1000)}`).join('\n') : '-',
      'Round Valuation': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Valuation.slice(0, 1000)}`).join('\n') : '-',
      // 'Intellectual Property':Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `Patents=${info.patents},Copyrights= ${info.copyRights},TradeMarks=${info.tradeMarks},Trade Secreats= ${info.tradeSecrets}`).join('\n') : '-',
      'Patents': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.patents}`).join('\n') : '-',
      'Copyrights': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.copyRights}`).join('\n') : '-',
      'Trademarks': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.tradeMarks}`).join('\n') : '-',
      'Trade Secrets': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.tradeSecrets}`).join('\n') : '-',

      'CEO Name': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_name : '-') : '-',
      'CEO Mobile': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_mob : '-') : '-',
      'CEO Email': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_email : '-') : '-',
      'CEO Experience': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_exp : '-') : '-',
      'CEO LinkedIn': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).linkedinProfile : '-') : '-',
      // 'CEO Name': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.filter(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.filter(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) : '-') : '-',
      'Team Size': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.filter(info => info.team_meb_designation !== 'CEO').length : '-',
      'Team Details': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.filter(info => info.team_meb_designation !== 'CEO').map(info => `Name: ${info.team_meb_name}, Mobile: ${info.team_meb_mob}, Email: ${info.team_meb_email}, Experience: ${info.team_meb_exp},Designation: ${info.team_meb_designation},Linkedin_Profile: ${info.linkedinProfile}`).join('\n') : '-', //
      // 'Team Member Name': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Name: ${info.team_meb_name}`).join('\n') : '-',
      // 'Team Member Mobile': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Mobile: ${info.team_meb_mob}`).join('\n') : '-',
      // 'Team Member Email': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Email: ${info.team_meb_email}`).join('\n') : '-',
      // 'Team Member Experience': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Experience: ${info.team_meb_exp}`).join('\n') : '-',
      // 'Team Member Designation': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Designation: ${info.team_meb_designation}`).join('\n') : '-',
      // 'Team Member LinkedIn': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `LinkedIn: ${info.linkedinProfile}`).join('\n') : '-',
//       SocialInfo: [
//   d?.social_info?.facebook ? `Facebook=${d.social_info.facebook}` : '',
//   d?.social_info?.instagram ? `Instagram=${d.social_info.instagram}` : '',
//   d?.social_info?.twitter ? `Twitter=${d.social_info.twitter}` : '',
//   d?.social_info?.linkedIn ? `LinkedIn=${d.social_info.linkedIn}` : '',
//   d?.social_info?.youtube ? `Youtube=${d.social_info.youtube}` : '',
//   d?.social_info?.koo ? `Koo=${d.social_info.koo}` : ''
// ]
//   .filter(item => item !== '') // Exclude empty items
//   .join(', '),
      // 'Social Info': d?.social_info ? `Facebook=${d.social_info.facebook ? d.social_info.facebook : '- '}\n Instagram=${d.social_info.instagram ? d.social_info.instagram : '-'}\n Twitter=${d.social_info.twitter ? d.social_info.twitter : '-'}\n LinkedIn=${d.social_info.linkedIn ? d.social_info.linkedIn : '-'}\n Youtube=${d.social_info.youtube ? d.social_info.youtube : '-'}\nKoo=${d.social_info.koo ? d.social_info.koo : '-'}` : '-',
      Facebook: d?.social_info?.facebook ? d.social_info.facebook : '-',
      Instagram: d?.social_info?.instagram ? d.social_info.instagram : '-',
      Twitter: d?.social_info?.twitter ? d.social_info.twitter : '-',
      LinkedIn: d?.social_info?.linkedIn ? d.social_info.linkedIn : '-',
      Youtube: d?.social_info?.youtube ? d.social_info.youtube : '-',
      // Koo: d?.social_info?.koo ? d.social_info.koo : '-',
      // 'Profile Email': d?.contact_info?.email,
      'Login Email':d?.login_email ?  d?.login_email : '-',
      'Created At': d?.created_at ? new Date(d?.created_at) : '-',
      // 'Updated At':new Date(d?.updated_at),
      'Last Login Date':d?.login_info ? formatDate(new Date(d.login_info.last_login_time) ) : '-',
      'Last Login Time':d?.login_info ? formatTime(new Date(d.login_info.last_login_time)):'-'
    };
    if (this.activeFilter === 'APPROVED') {
      result.ApprovedBy = d.approved_by ? d.approved_by : '-';
    }else if(this.activeFilter === 'REJECTED'){
      result.RejectedBy = d.approved_by ? d.approved_by : '-';
    }else if(this.activeFilter !== 'PENDING'){
      result['Approved/Rejected By'] = d.approved_by ? d.approved_by : '-';
    }
    return result;
  });
  this.exportAsXLSX(data);
  // this.generateExcelFile(data, "myData.xlsx");
  console.log("Export:", data);
  
}


 
  downloadFile(d) {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Applications",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "applications",
      headers: ["Name", "Domain", "Sector", "Location", "Description", "Stage"],
    };
    const csvdata = [
      {
        Name: d.name,
        Domain: d.domain,
        City: d.city,
        Sector: d.sector,
        Description: d.short_description,
        Stage: d.startup_stage,
      },
    ];
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvdata);
  }

  editData(data) {
    this.data = data;
    console.log("edit statupDATA:", this.data);
    this.modalUpdateForm.controls["modalStartUpName"].setValue(data.name);
  }
  updatestartupModel(id) {
    let data = {};
    this.startupService.editStartup(data, id).subscribe(
      (res: any) => {
        console.log("statSucces:", res);
        this.toastrService.success("Data Updated Successfully!");
      },
      (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update records!");
      }
    );
  }


  
  changestatus(id: string, value: string) {
    // localStorage.setItem('myData', 'Startup');
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = '0'; 
      htmlElement.style.position = 'sticky';
    }
    this.mat_data = value;
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Startup' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              status: value,
            };
            this.startupService.actionStartup(data, id).subscribe(
              (res: any) => {
                console.log("change_status", res);
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
                const updatedStartupIndex = this.data.findIndex(startup => startup.id === id);
              if (updatedStartupIndex !== -1) {
                this.data[updatedStartupIndex].status = value;
                 this.data[updatedStartupIndex].approved_by = res?.results[0]?.approved_by || ''
              }
              },
              (error) => {
                this.inValidMessage = true;
                this.toastrService.warning("Unable to Update Status!");
              }
            );
          }
        })
  }

  getDomains() {
    this.challengeService.getalldomains().subscribe((res: any) => {
      this.alldomains = res.results;
      let data = [];
      for (var i = 0; i < this.alldomains.length; i++) {
        data.push({
          item_id: i + 1,
          item_text: this.alldomains[i].name,
        });
      }
      this.dropdownList = [...data];
      // console.log('data_domain',data);
      this.groupbydomains = _.groupBy(this.alldomains, "name");
      console.log("group domains", this.groupbydomains);
    });
  }
  async onItemSelectDomain(event) {
    await this.groupbysectors(event);
    console.log("sectors", this.sectors);
    for (var i = 0; i < this.sectors.length; i++) {
      this.tempdata.push({
        item_id: i + 1,
        item_text: this.sectors[i].name,
      });
    }
    this.dropdownList1 = [...this.tempdata];
    console.log("sectors_dropdown", this.dropdownList1);
  }

  async onItemDeSelectDomain(event) {
    console.log("event", event);
  }
  async groupbysectors(event) {
    return new Promise((resolve, reject) => {
      this.challengeService.setSectorData(
        this.groupbydomains[`${event.item_text}`][0]["sectors"]
      );
      this.sectors.push(
        ...this.groupbydomains[`${event.item_text}`][0]["sectors"]
      );
      resolve(this.sectors);
    });
  }

  backfilter(){
    if(this.viewback == 'true')
    {
      const getArray = localStorage.getItem('filterArray');
      console.log(getArray,'getArray000')
      const parsedArray = JSON.parse(getArray);
      this.myArray = parsedArray;
      const stateValue = parsedArray.find(item => item.name === 'state')?.value;
      const statusValue = parsedArray.find(item => item.name === 'status')?.value;
      const cityvalue = parsedArray.find(item => item.name === 'city')?.value;
      const domainvalue = parsedArray.find(item => item.name === 'domain')?.value;
      this.selectedStates = stateValue;
      this.selectedStatus = statusValue;
      this.selectcity = cityvalue
      this.selectedItems = domainvalue;
    }
    

      if(localStorage.getItem('status'))
      {
        this.activeFilter = localStorage.getItem('status');
      }

     


    let data = {
      filters: this.myArray,
      search_entity: "STARTUP",
      "page": 0, "page_size": 10

    };

   
    console.log("data____0603", data);
   
   
      this.startupService.filterStartup(data).subscribe((res: any) => {
        // console.log("res myarray___0603:", res);
        this.data = res.results;       
        this.approved_count = res.approved_count;
        this.rejected_count = res.rejected_count;
        this.pending_count = res.pending_count; 
        this.totalItems = +res.total_search_results_count;
        for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
          this.pagelinks.push(i);
      }
  
        // Example dynamic pagination generation
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages; // Replace with your dynamic value
      this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
        // this.updateStatusCounts(this.data);
        this.loading = false;
      });
     
    
  }



  filterItem(filterValue) {
    console.log('filterValue',filterValue);
    this.page_index = 0;
    this.selectedindex = 0;
    console.log(filterValue,'filterValue000')   
     if(filterValue == 'null')
     {
      this.activeFilter = null;
     }
    
   
    localStorage.removeItem('status');
    this.myArray = [];
    var domains = [];
    

    
    if(this.selectdomain && this.selectdomain.length > 0)
    {
      for (var i = 0; i < this.selectdomain.length; i++) {
        domains.push(this.selectdomain[i].item_text);
      }
      if (this.selectdomain.length != 0) {
        this.myArray.push({
          name: "domain",
          value: domains,
        });
      }
    }
    
    var sector = [];

    if (this.selectcity !== "") {
      this.myArray.push({
        name: "city",
        value: this.selectcity,
      });
      console.log("selectcity:", this.myArray);
    }
    console.log('selected_state',this.selectstate);
    if (this.selectstate && this.selectstate.length > 0 ) {
      this.myArray.push({
        name: "state",
        value: this.selectstate,
      });
    }

    if (this.selectedScheme && this.selectedScheme.length > 0) {
      console.log('scheme_ids ----');
      console.log(this.selectedScheme);
      this.myArray.push({
        name : "scheme_ids",
        value: this.selectedScheme.map(item => item.id)
      })
    }

    if (this.selectstatus?.length != 0) {
      this.myArray.push({
        name: "status",
        value: this.selectstatus,
      });
      this.activeFilter = this.selectstatus;
      this.filterValue1 = this.selectstatus
      
      console.log("selectcity1223:", this.myArray);
    }
    
    const jsonString = JSON.stringify(this.myArray);
    localStorage.setItem('filterArray', jsonString); 
  
    console.log('my_array',this.myArray);

    let data = {
      filters: this.myArray,
      search_entity: "STARTUP",
      "page": 0, "page_size": 10

    };
   
    if(this.filterValue == null){
      this.startupService.filterStartup(data).subscribe((res: any) => {
        this.data = res.results;
        this.approved_count = res.approved_count;
        this.pending_count = res.pending_count;
        this.rejected_count = res.rejected_count;
        this.totalItems = +res.total_search_results_count;
        this.pagelinks = [];
      for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
        this.pagelinks.push(i);
    }
    console.log('filter_pages');
    console.log(this.pagelinks);
    this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
        this.onPageChange(1);
        if(data.filters.length > 0 && data.filters[0]['name'] === 'status'){
          // this.updateStatusCounts(this.data);
        }else{
          this.updateStatusCounts(this.data);
        }
        const statusCount = {
          approvedData: this.approvedCount,
          rejectedData: this.rejectedCount,
          pendingData: this.pendingCount
        };
        const countstring = JSON.stringify(statusCount);
        localStorage.setItem('countdata', countstring);
      });
     
    }

    else{
    if (!this.myArray.some(item => item.name === 'status')) {
          this.myArray.push({
            "name": "status",
            "value": this.filterValue              
        });
       }
    let data = {
      "filters": [
          {
              "name": "status",
              "value": this.filterValue              
          },...this.myArray
      ],
      "search_entity": "STARTUP",
      "page": 0, "page_size": 10

  }
 
    console.log("data____filterdata",this.myArray, data);   
    this.startupService.filterStartup(data).subscribe((res: any) => {
      console.log("pendinggggg.........:", res);

      this.data = res.results;
      if(data.filters.length > 0 && data.filters[0]['name'] === 'status'){
        this.approved_count = res?.approved_count || 0;
        this.rejected_count = res?.rejected_count || 0;
        this.pending_count = res?.pending_count || 0;
      }else{
        this.updateStatusCounts(this.data);
      }
      this.loading = false;
      this.totalItems = +res.total_search_results_count;
      this.pagelinks = [];
      for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
        this.pagelinks.push(i);
    }
    console.log('filter_pages');
    console.log(this.pagelinks);
    this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
      
    });
  }
  }
  countFilter(data,count?:any)
  {
    this.totalpages = count;
    this.page_index = 0;
    this.selectedindex = 0;
    this.myArray = [];
    var domains = [];
   
    if(this.selectdomain && this.selectdomain.length > 0)
    {
      for (var i = 0; i < this.selectdomain.length; i++) {
        domains.push(this.selectdomain[i].item_text);
      }
      if (this.selectdomain.length != 0) {
        this.myArray.push({
          name: "domain",
          value: domains,
        });
      }
    }
    var sector = [];
    

    if (this.selectcity !== "") {
      this.myArray.push({
        name: "city",
        value: this.selectcity,
      });
      console.log("selectcity:", this.myArray);
    }
    if (this.selectstate && this.selectstate.length > 0 ) {
      this.myArray.push({
        name: "state",
        value: this.selectstate,
      });
    }
    
    let fitlere_data = data;
    this.activeFilter = data;
    this.filterValue1 = data; 
    
    localStorage.removeItem('status')
    if(this.viewback == 'true')
   {
     const getArray = localStorage.getItem('filterArray');
    const parsedArray = JSON.parse(getArray);
    this.myArray = parsedArray;
     this.myArray = this.myArray?.filter(item => item.name !== 'status') || [];
   }
    // this.myArray = this.myArray.filter(item => item.name !== 'status');
    if(data == 'All')
    {
      // this.myArray = this.myArray.filter(item => item.name !== 'status');
      this.myArray.push({
        name: "status",
        value: ["PENDING", "REJECTED", "APPROVED"]
      });
      this.activeFilter = null;
      this.selectedStatus = ["PENDING", "REJECTED", "APPROVED"];
      const jsonString = JSON.stringify(this.myArray);
      localStorage.setItem('filterArray', jsonString); 
     
    }
    else {

      this.myArray.push({
        name: "status",
        value: [data],
      });
      this.selectedStatus = [data];
      const jsonString = JSON.stringify(this.myArray);
      localStorage.setItem('filterArray', jsonString); 
    }
  
   
    
    const jsonString = JSON.stringify(this.myArray);
    localStorage.setItem('filterArray', jsonString); 
   
    let data1 = {
      filters: this.myArray,
      "search_entity": "STARTUP",
      "page": this.page_index , "page_size": 10

  }
    this.startupService.filterStartup(data1).subscribe((res: any) => {     
     
      this.data = res.results;
      this.approved_count = res.approved_count;
      this.pending_count = res.pending_count;
      this.rejected_count = res.rejected_count;
      this.totalItems = +res.total_search_results_count;
      this.pagelinks = [];
      for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
        this.pagelinks.push(i);
    }
    

      // Example dynamic pagination generation
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages; // Replace with your dynamic value
    this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
    this.updateStatusCounts(this.data);
    this.onPageChange(1) 
      const statusCount = {
        approvedData: this.approvedCount,
        rejectedData: this.rejectedCount,
        pendingData: this.pendingCount
      };
      const countstring = JSON.stringify(statusCount);
      localStorage.setItem('countdata', countstring);
    });

  }
  countAllFilter()
  {
    this.activeFilter = null;
    localStorage.removeItem('status');
    this.onPageChange(1) 
    this.resetFilters()
    this.listAllStartups();
     this.updateStatusCounts(this.data);
  }

  

  resetFilters() {
    this.schemeSelected = false;
    this.selectedScheme = {}
    // this.resetMode = false;
    // this.filterMode = true;

    // clear the filter criteria object
    this.data = {};
      this.selectcity = "";
      this.selectstate = "";
      this.selectdomain = "";
      // this.selectsector = "";
      this.selectstatus = "";
    // reset the filtered data to the original data
    this.listAllStartups();
    this.activeFilter = null;
    this.onPageChange(1) 
    localStorage.removeItem('filterArray');
    localStorage.removeItem('status');
    localStorage.removeItem('viewback')
    this.viewback = 'false';
    
    // this.data = this.originalData;
    // this.updateStatusCounts(this.data);
  }
  preview(d) {
    console.log("pCheck::", d);
    this.router.navigateByUrl(`/admin/pdftemplate/${d}`);
    if(this.filterValue1.length > 0)
    {
      localStorage.setItem('status',this.filterValue1)
      console.log("filte value is ==> ", this.filterValue1)
    }
  }

  getNextSet() {
    this.page_index = this.page_index + 10;
    this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10);
  }

  getPrevSet() {
    this.page_index = this.page_index - 10;
    this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10);
  }


  getSerialNo(i:number) {
    return i+(this.selectedindex*10);
  }
}
